import React from 'react';
import { Route, BrowserRouter, Redirect } from "react-router-dom";

import Amplify, { PubSub } from 'aws-amplify';
import amplifyConfig from './amplify-config';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';

import { StateProvider } from './State';

import Content from './components/Content';
import CognitoReactSignInForm from './pages/CognitoReactSignInForm';

import './App.css';

Amplify.configure(amplifyConfig);
// Apply plugin with configuration
Amplify.addPluggable(new AWSIoTProvider({
    aws_pubsub_region: 'us-east-2',
    aws_pubsub_endpoint: 'wss://a23kawqisrluu3-ats.iot.us-east-2.amazonaws.com/mqtt',
}));

const App = () => {
    const initialState = {
        login: false,
        cognito: {
            username: null,
            picture: null,
            email: null,
        },
        device: {}
    };

    const reducer = (state, action) => {
        console.log("reducer");
        switch (action.type) {
            case 'change':
                console.log("change");
                console.log(action.newData);
                return {
                    ...state,
                    cognito: action.newData
                };
            break;
            case 'changeD':
                console.log("changeD");
                console.log(action.newData);
                return {
                    ...state,
                    device: action.newData
                };
            break;

            default:
                return state;
        }
    }
  
    return (
        <StateProvider initialState={initialState} reducer={reducer}>
            <BrowserRouter>
                <Route path='/home' component={Content} />
                <Route exact path='/login' component={CognitoReactSignInForm}/>
                <Route exact path='/'>
                    <Redirect to="/home/devices" />
                </Route>
            </BrowserRouter>
        </StateProvider>
    );
};

export default App;
