import { Auth } from 'aws-amplify';
import React from 'react';
import Avatar from 'react-avatar';
import { Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { useStateValue } from '../../State';
import "./TopBar.css";



const TopBar = () => {
    const [{ cognito }, dispatch] = useStateValue();

    const handleLogout = async () => {
        await Auth.signOut()
            .then((data) => {
                console.log(data);
                alert("saiu")
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return(
        <Container id="TopBar">
            <Row>
                <Col md={6}>
                    {/* <InputGroup>
                        <Input />
                        <InputGroupAddon addonType="append">
                            <Button color="secondary">To the Right!</Button>
                        </InputGroupAddon>
                        
                    </InputGroup> */}
                    {/* comentei esse InputGroupAddon por enquanto */}
                </Col>
                <Col md={3}/>
                <Col md={3}>
                    <Row>
                        <Avatar size={40} round={true} src={cognito.picture} />
                        <UncontrolledDropdown setActiveFromChild>
                            <DropdownToggle tag="a" className="nav-link" caret>
                                {cognito.username}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem tag="a" href="/login" onClick={handleLogout} active>Logout</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Row>
                </Col>
            </Row>
        </Container>
    );    
};

export default TopBar;