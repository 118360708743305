// const amplifyConfig = {
//     "aws_cognito_region": "us-east-2",
//     "aws_user_pools_id": "us-east-2_SFrYV4huf",
//     "aws_user_pools_web_client_id": "1u2g9ethgmq0qkrnjtdfa2i3n"
// };

import { Auth } from "aws-amplify";

const amplifyConfig = {
    Auth: {
        region: 'us-east-2', 
        identityPoolId: 'us-east-2:4ef0bcf5-cbd5-4b57-8fa5-c27c9a07748e',
        userPoolId: "us-east-2_SFrYV4huf",
        userPoolWebClientId: '1u2g9ethgmq0qkrnjtdfa2i3n',
    },
    API: {
        endpoints: [
            {
                name: 'SimemapTenants',
				region: 'us-east-2',
                endpoint: "https://api.simemap.com/tenants",
				custom_header: async () => {
					return { 
                        Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` 
                    }
				}
            }
        ]
    }
}

export default amplifyConfig;