import { Auth } from 'aws-amplify';
import AwsIot from 'aws-iot-device-sdk';
import HighchartsReact from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
// Import Highcharts
import Highcharts from "highcharts/highstock";
import moment from "moment-timezone";
import React, { Component } from 'react';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import { FormControl } from 'react-bootstrap';
import ToggleButton from 'react-toggle-button';
import { ButtonGroup, Col, Container, Row } from 'reactstrap';
import { StateContext } from '../../State';
import { getChartsOfBoard, getConsumo, getOnda } from './../../services/api';

HC_more(Highcharts); //init module

const LIMIAR_LIGADO_DESLIGADO = 35;
const corV1 = '#0000FF';
const corV2 = '#006699';
const corV3 = '#7300E6';
const corI1 = '#FF0000';
const corI2 = '#FFCC00';
const corI3 = '#99CC00';
const corP1 = '#404040';
const corP2 = '#707070';
const corP3 = '#999999';
const corPtrif = '#000000';

var series = [];

const corDoConsumo = '';
window.moment = moment;

class DeviceCharts extends Component {
    constructor(props){
        super(props);
        this.changePicker = this.changePicker.bind(this);

        const timezone = new Date().getTimezoneOffset()

        // this.tipoVisGrafDetalhes = this.tipoVisGrafDetalhes.bind(this);

        Highcharts.setOptions({
            global: {
                timezoneOffset: timezone
            }
        });
    }

    state = {
        tipoAgrupGraf: 0,
        tipoGrandeza: 0,
        qualCanal: 0,
        MqttClient: undefined,
        picker: {
            start: moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0,0,0,0)),
            end: moment(moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0,0,0,0)),).add(1, "days").subtract(1, "seconds")
        },
        periodo1: {
            pontos: 0,
            eletric: {
                v_1: { max: 0, min: 0, med: 0, now: 0 },
                i_1: { max: 0, min: 0, med: 0, now: 0 },
                p_1: { max: 0, min: 0, med: 0, now: 0 },
                c_1: { total: 0 }
            },
            V: [], I: [], P: [], KWH: [],
        },
        periodo2: {
            pontos: 0,
            eletric: {
                v: { max: 0, min: 0, med: 0, now: 0 },
                i: { max: 0, min: 0, med: 0, now: 0 },
                p: { max: 0, min: 0, med: 0, now: 0 },
                c: { total: 0 }
            },
            V: [], I: [], P: [], KWH: [],
        },
        periodo3: {
            pontos: 0,
            eletric: {
                v: { max: 0, min: 0, med: 0, now: 0 },
                i: { max: 0, min: 0, med: 0, now: 0 },
                p: { max: 0, min: 0, med: 0, now: 0 },
                c: { total: 0 }
            },
            V: [], I: [], P: [], KWH: [],
        },
        periodo_trif: {
            pontos: 0,
            P: [],
            KWH: [],
        },
        onda1: {
            vca: [],
            ica: [],
            modulo_Vca: [[undefined], [undefined]],
            angulo_Vca: [[undefined], [undefined]],
            modulo_Ica: [[undefined], [undefined]],
            angulo_Ica: [[undefined], [undefined]],
        },
        tempoReal: {
            v_1: 0,
            i_1: 0,
            p_1: 0,
            vca_onda_1: [],
            ica_onda_1: [],
            v_2: 0,
            i_2: 0,
            p_2: 0,
            vca_onda_2: [],
            ica_onda_2: [],
            v_3: 0,
            i_3: 0,
            p_3: 0,
            vca_onda_3: [],
            ica_onda_3: [],
            p_trif: 0
        },
        consumo: {
            consumo: [],
        },
    }

    static contextType = StateContext;


    async selectPoint(params) {
        //const { board } = this.props.match.params;
        const board = this.context[0].device.device.reference;

        var onda1 = await getOnda({
            deviceid: board, 
            channel: 'c1', 
            dt: params.point.category
        })

        var DFT = this.fourier({
            onda_Vca: onda1.res[0].vca_c1, 
            onda_Ica: onda1.res[0].ica_c1
        });

        var modulo_Vca = [], angulo_Vca = [], 
            modulo_Ica = [], angulo_Ica = [];

        for(var i=0; i<DFT.length; i++){
            modulo_Vca.push([DFT[i][0], DFT[i][1]]   );
            angulo_Vca.push([DFT[i][0], DFT[i][2]+90]);
            modulo_Ica.push([DFT[i][0], DFT[i][3]]   );
            angulo_Ica.push([DFT[i][0], DFT[i][4]+90]);
        }

        // console.log(this); 
        // console.log(params.point.category); 
        // console.log(params.point.y);

        /*console.group("ONDAS");
        console.log(DFT);
        console.log(modulo_Vca);
        console.log(angulo_Vca);
        console.log(modulo_Ica);
        console.log(angulo_Ica);
        console.groupEnd();*/

        this.setState({
            onda1: {
                vca: onda1.res[0].vca_c1,
                ica: onda1.res[0].ica_c1,
                modulo_Vca: modulo_Vca, 
                angulo_Vca: angulo_Vca, 
                modulo_Ica: modulo_Ica, 
                angulo_Ica: angulo_Ica
            },
        });
    }

    principalChart({title, V_CH1, I_CH1, P_CH1, KWH_CH1, V_CH2, I_CH2, P_CH2, KWH_CH2, V_CH3, I_CH3, P_CH3, KWH_CH3, P_CHtrif, KWH_CHtrif})
    {        
        
        const board = this.context[0].device.device;

        if (this.state.tipoAgrupGraf === "1")
        {
            series=[]
            if (board.canal === 1)
            {
                // series=[]
                series.push({
                    name: 'Potência Trifasica',
                    sym: 'W_CHtrif',
                    data: P_CH1,
                    color: corPtrif,
                    visible: true
                })
            }
            if (board.canal === 7)
            {
                // series=[]
                series.push({
                    name: 'Potência Trifasica',
                    sym: 'W_CHtrif',
                    data: P_CHtrif,
                    color: corPtrif,
                    visible: true
                })
            }
        
        }

        if (this.state.tipoAgrupGraf === "2") //por grandeza (V, I, P ou consumo/dia)
        {   
            series=[]
            if (this.state.tipoGrandeza === "1") //tensão
            {
                if(V_CH1.length>0)
                {
                    series.push({
                        name: 'Tensão A',
                        sym: 'V_CH1',
                        data: V_CH1,
                        color: corV1,
                        visible: true,
                    })
                }                
                if(V_CH2.length>0)
                {
                    series.push({
                        name: 'Tensão B',
                        sym: 'V_CH2',
                        data: V_CH2,
                        color: corV2,
                        visible: true,
                    })
                }
                if(V_CH3.length>0)
                {
                    series.push({
                        name: 'Tensão C',
                        sym: 'V_CH3',
                        data: V_CH3,
                        color: corV3,
                        visible: true,
                    })
                }
            }
            if (this.state.tipoGrandeza === "2") //corrente
            {
                if(V_CH1.length>0)
                {
                    series.push({
                        name: 'Corrente A',
                        sym: 'I_CH1',
                        data: I_CH1,
                        color: corI1,
                        visible: true,
                    })
                }                
                if(V_CH2.length>0)
                {
                    series.push({
                        name: 'Corrente B',
                        sym: 'I_CH2',
                        data: I_CH2,
                        color: corI2,
                        visible: true,
                    })
                }
                if(V_CH3.length>0)
                {
                    series.push({
                        name: 'Corrente C',
                        sym: 'I_CH3',
                        data: I_CH3,
                        color: corI3,
                        visible: true,
                    })
                }
            }
            if (this.state.tipoGrandeza === "3") //potência
            {
                if(V_CH1.length>0)
                {
                    series.push({
                        name: 'Potência A',
                        sym: 'W_CH1',
                        data: P_CH1,
                        color: corP1,
                        visible: true
                    })
                }
                
                if(V_CH2.length>0)
                {
                    series.push({
                        name: 'Potência B',
                        sym: 'W_CH2',
                        data: P_CH2,
                        color: corP2,
                        visible: true
                    })
                }

                if(V_CH3.length>0)
                {
                    series.push({
                        name: 'Potência C',
                        sym: 'W_CH3',
                        data: P_CH3,
                        color: corP3,
                        visible: true
                    })
                }
            }
        }

        if (this.state.tipoAgrupGraf === "3") 
        {   
            series=[]
            if (this.state.qualCanal === "1") //A
            {
                if(V_CH1.length>0)
                {
                    series.push({
                        name: 'Tensão A',
                        sym: 'V_CH1',
                        data: V_CH1,
                        color: corV1,
                        visible: true,
                        // showInLegend: false
                    })
                    series.push({
                        name: 'Corrente A',
                        sym: 'C_CH1',
                        data: I_CH1,
                        color: corI1,
                        visible: true
                    })
                    series.push({
                        name: 'Potência A',
                        sym: 'W_CH1',
                        data: P_CH1,
                        color: corP1,
                        visible: true
                    })
                    series.push({
                        name: 'Consumo A',
                        sym: 'kWh_CH1',
                        data: KWH_CH1,
                        visible: false
                    })
                }
            }
            if (this.state.qualCanal === "2") //B
            {
                if(V_CH2.length>0)
                {
                    series.push({
                        name: 'Tensão B',
                        sym: 'V_CH2',
                        data: V_CH2,
                        color: corV2,
                        visible: true,
                        // showInLegend: false
                    })
                    series.push({
                        name: 'Corrente B',
                        sym: 'C_CH2',
                        data: I_CH2,
                        color: corI2,
                        visible: true
                    })
                    series.push({
                        name: 'Potência B',
                        sym: 'W_CH2',
                        data: P_CH2,
                        color: corP2,
                        visible: true
                    })
                    series.push({
                        name: 'Consumo B',
                        sym: 'kWh_CH2',
                        data: KWH_CH2,
                        visible: false
                    })
                }
            }
            if (this.state.qualCanal === "3") //C
            {
                if(V_CH3.length>0)
                {
                    series.push({
                        name: 'Tensão C',
                        sym: 'V_CH3',
                        data: V_CH3,
                        color: corV3,
                        visible: true,
                        // showInLegend: false
                    })
                    series.push({
                        name: 'Corrente C',
                        sym: 'C_CH3',
                        data: I_CH3,
                        color: corI3,
                        visible: true
                    })
                    series.push({
                        name: 'Potência C',
                        sym: 'W_CH3',
                        data: P_CH3,
                        color: corP3,
                        visible: true
                    })
                    series.push({
                        name: 'Consumo C',
                        sym: 'kWh_CH3',
                        data: KWH_CH3,
                        visible: false
                    })
                }
            }                        
        }

        if (this.state.tipoAgrupGraf === "4") 
        {   
            series=[]
            if(V_CH1.length>0)
            {
                series.push({
                    name: 'Tensão A',
                    //type: 'area',
                    sym: 'V_CH1',
                    data: V_CH1,
                    color: corV1,
                    visible: true,
                    // showInLegend: false
                })
                series.push({
                    name: 'Corrente A',
                    sym: 'C_CH1',
                    data: I_CH1,
                    color: corI1,
                    visible: true
                })
                series.push({
                    name: 'Potência A',
                    sym: 'W_CH1',
                    data: P_CH1,
                    color: corP1,
                    visible: true
                })
                series.push({
                    name: 'Consumo A',
                    sym: 'kWh_CH1',
                    data: KWH_CH1,
                    visible: true
                })
            }
            
            if(V_CH2.length>0)
            {
                series.push({
                    name: 'Tensão B',
                    //type: 'area',
                    sym: 'V_CH2',
                    data: V_CH2,
                    color: corV2,
                    visible: false
                })
                series.push({
                    name: 'Corrente B',
                    sym: 'C_CH2',
                    data: I_CH2,
                    color: corI2,
                    visible: false
                })
                series.push({
                    name: 'Potência B',
                    sym: 'W_CH2',
                    data: P_CH2,
                    color: corP2,
                    visible: false
                })
                series.push({
                    name: 'Consumo B',
                    sym: 'kWh_CH2',
                    data: KWH_CH2,
                    visible: false
                })
            }

            if(V_CH3.length>0)
            {
                series.push({
                    name: 'Tensão C',
                    sym: 'V_CH3',
                    data: V_CH3,
                    color: corV3,
                    visible: false
                })
                series.push({
                    name: 'Corrente C',
                    sym: 'C_CH3',
                    data: I_CH3,
                    color: corI3,
                    visible: false
                })
                series.push({
                    name: 'Potência C',
                    sym: 'W_CH3',
                    data: P_CH3,
                    color: corP3,
                    visible: false
                })
                series.push({
                    name: 'Consumo C',
                    sym: 'kWh_CH3',
                    data: KWH_CH3,
                    visible: false
                })
            }
        }
        

        return {
            chart: {
                type: 'line',
                zoomType: 'x',
                panning: true,
                panKey: 'shift'
            },
            title: { text: title },
            xAxis: {
                type: 'datetime',
                ordinals: false,
                useUTC: false,
            },
            series: series,
            plotOptions: {
                visible: false,
                series: {
                  cursor: 'pointer',
                  point: {
                    events: {
                      click: (e) => this.selectPoint(e)
                    }
                  }
                }
            }
        };
    }
    consumoChart({title, consumo}){
        return {
            chart: {
                type: 'column',
                zoomType: 'x',
                panning: true,
                panKey: 'shift'
            },
            title: {
                text: title 
            },
            xAxis: {
                type: 'datetime',
                ordinals: false,
                // labels: {
                //     format: '{value:%Y-%m-%d}',
                //     // formatter: function(p) {
                //     //     console.log(p);
                //     //     return Highcharts.dateFormat('%Y-%m-%d', this.value)
                //     // }
                // }   
            },
            series: [
                {
                    name: 'Consumo C1',
                    //type: 'area',
                    sym: 'kWh/dia',
                    data: consumo
                }
            ]
        };
    }
    dftChart({ title, modulo_Vca, modulo_Ica }){
        return {
            chart: {
                type: 'column',
                zoomType: 'x',
                panning: true,
                panKey: 'shift'
            },
            title: { text: title },
            xAxis: { ordinals: true },
            series: [
                {
                    name: 'vca',
                    //type: 'area',
                    sym: 'kWh/dia',
                    data: modulo_Vca,
                    color: corV1
                },{
                    name: 'ica',
                    //type: 'area',
                    sym: 'kWh/dia',
                    data: modulo_Ica,
                    color: corI1,
                }
            ]
        };
    }
    ondasChart({title, vca, ica}){
        return {
            chart: {
                type: 'line',
                zoomType: 'x',
                panning: true,
                panKey: 'shift'
            },
            title: { text: title },
            subtitle: {text: 'Formas de onda no instante '+'instanteclicado'+'.'},
            // seriesGroups: [
            //     {
            //         series: [
            //             {
            //                 name: 'Tensão',
            //                 sym: 'V',
            //                 data: this.state.tempoReal.vca_onda,//vca,
            //                 tooltip: {valueDecimals: 2, valueSuffix: "V"},
            //                 color: corV1,
            //                 // yAxis: 0
            //             }
            //         ],
            //         type: 'line'
            //     },
            //     {
            //         series: [
            //             {
            //                 name: 'Corrente',
            //                 sym: 'A',
            //                 data: this.state.tempoReal.ica_onda,//ica,
            //                 tooltip: {valueDecimals: 2, valueSuffix: "A"},
            //                 color: corI1,
            //                 // yAxis: 1
            //             }
            //         ],
            //         type: 'line'
            //     }
            // ],

            // yAxis:[],
            // series: [], 

            series: [
                {
                    name: 'V1',
                    sym: 'V',
                    // data: vca, //se quiser apenas o valor clicado
                    data: this.state.tempoReal.vca_onda_1, //se quiser atualizar com toda nova onda
                    tooltip: {valueDecimals: 2, valueSuffix: "V"},
                    color: corV1,
                    // yAxis: 0
                }, 
                {
                    name: 'I1',
                    sym: 'A',
                    // data: ica, //se quiser apenas o valor clicado
                    data: this.state.tempoReal.ica_onda_1, //se quiser atualizar com toda nova onda
                    tooltip: {valueDecimals: 2, valueSuffix: "A"},
                    // yAxis: 1,
                    color: corI1                    
                },
                {
                    name: 'V2',
                    sym: 'V',
                    // data: vca, //se quiser apenas o valor clicado
                    data: this.state.tempoReal.vca_onda_2, //se quiser atualizar com toda nova onda
                    tooltip: {valueDecimals: 2, valueSuffix: "V"},
                    color: corV2,
                    // yAxis: 0
                }, 
                {
                    name: 'I2',
                    sym: 'A',
                    // data: ica, //se quiser apenas o valor clicado
                    data: this.state.tempoReal.ica_onda_2, //se quiser atualizar com toda nova onda
                    tooltip: {valueDecimals: 2, valueSuffix: "A"},
                    // yAxis: 1,
                    color: corI2                    
                },
                {
                    name: 'V3',
                    sym: 'V',
                    // data: vca, //se quiser apenas o valor clicado
                    data: this.state.tempoReal.vca_onda_3, //se quiser atualizar com toda nova onda
                    tooltip: {valueDecimals: 2, valueSuffix: "V"},
                    color: corV3,
                    // yAxis: 0
                }, 
                {
                    name: 'I2',
                    sym: 'A',
                    // data: ica, //se quiser apenas o valor clicado
                    data: this.state.tempoReal.ica_onda_3, //se quiser atualizar com toda nova onda
                    tooltip: {valueDecimals: 2, valueSuffix: "A"},
                    // yAxis: 1,
                    color: corI3                    
                }
            ]            
        };
    }
    polarChart({ modulo_Vca, angulo_Vca, modulo_Ica, angulo_Ica }){
        return {
            title: {text: 'Diagrama fasorial'},
            subtitle: {text: ''},
            
            chart: {polar: true},
            pane: {startAngle: -270, endAngle: 90},

            xAxis: {
                tickInterval: 30,
                min: 0,
                max: 360,
                reversed: true,
                labels: {
                    formatter: function() {return this.value + '°';}
                }
            },
            yAxis: [{min: 0, max: modulo_Vca}, {min: 0, max: modulo_Ica}],
        
            // plotOptions: {
            //     series: {
            //         pointStart: 0,
            //         pointInterval: 45
            //     },
            //     column: {
            //         pointPadding: 0,
            //         groupPadding: 0
            //     }
            // }, 
        
            series: [{
                type: 'line',
                name: 'Vca',
                marker: {symbol: 'triangle'},
                pointInterval: angulo_Vca,
                data: [0, modulo_Vca],
                color: corV1
            }, {
                type: 'line',
                name: 'Ica',
                marker: {symbol: 'triangle'},
                pointInterval: angulo_Ica,
                data: [0, modulo_Ica],
                yAxis: 1,
                color: corI1
            }]
        };
    }

    async mountChartPrincipal({ dt_start, dt_end }){
        //const { board } = this.props.match.params;
        const board = this.context[0].device.device;

        var v_c1 = [], i_c1 = [], p_c1 = [], kwh_c1 = [];
        var v_c2 = [], i_c2 = [], p_c2 = [], kwh_c2 = [];
        var v_c3 = [], i_c3 = [], p_c3 = [], kwh_c3 = [];
        var p_ctrif = [], kwh_ctrif = [];

        if ((board.canal === 1) || (board.canal === 3) || (board.canal === 5) || (board.canal === 7)) //combinações que incluem canal 1
        {
            var res1 = await getChartsOfBoard({
                deviceid: board.reference,
                // channel: [1, 2, 3], 
                // channel: board.canal,
                channel: 1,
                dt_start: dt_start.getTime(),
                dt_end:   dt_end.getTime()
            });
    
            if(res1 === [] || !Array.isArray(res1.data)){
                alert("sem dados disponiveis no canal 1");
                return 0;
            }
            for(let ponto of res1.data){
                // v_c1.push([ponto.timestamp, ponto[`v_c${board.canal}`]]);
                // i_c1.push([ponto.timestamp, ponto[`i_c${board.canal}`]]);
                // p_c1.push([ponto.timestamp, ponto[`p_c${board.canal}`]]);
                // kwh_c1.push([ponto.timestamp, ponto[`kwh_c${board.canal}`]]);
                v_c1.push([ponto.timestamp, ponto.v_c1]);
                i_c1.push([ponto.timestamp, ponto.i_c1]);
                p_c1.push([ponto.timestamp, ponto.p_c1]);
                kwh_c1.push([ponto.timestamp, ponto.kwh_c1]);
            }
            this.setState({
                periodo1: {
                    pontos: res1.data.length,
                    V: v_c1,
                    I: i_c1,
                    P: p_c1,
                    KWH: kwh_c1,
                    eletric: {
                        v_1: {
                            max: res1.Vef_max,
                            min: res1.Vef_min,
                            med: res1.Vef_med,
                        },
                        i_1: {
                            max: res1.Ief_max,
                            min: res1.Ief_min,
                            med: res1.Ief_med,
                        },
                        p_1: {
                            max: res1.Pef_max,
                            min: res1.Pef_min,
                            med: res1.Pef_med,
                        },
                        c_1: {
                            total: res1.Kwh_total
                        }
                    },
                },
            });
        }

        if ((board.canal === 2) || (board.canal === 3) || (board.canal === 6) || (board.canal === 7)) //combinações que incluem canal 2
        {
            var res2 = await getChartsOfBoard({
                deviceid: board.reference,
                channel: 2,
                dt_start: dt_start.getTime(),
                dt_end:   dt_end.getTime()
            });
    
            if(res2 === [] || !Array.isArray(res2.data)){
                alert("sem dados disponiveis no canal 2");
                return 0;
            }
            else
            {
                for(let ponto of res2.data){
                    v_c2.push([ponto.timestamp, ponto.v_c2]);
                    i_c2.push([ponto.timestamp, ponto.i_c2]);
                    p_c2.push([ponto.timestamp, ponto.p_c2]);
                    kwh_c2.push([ponto.timestamp, ponto.kwh_c2]);
                }
            }
            this.setState({
                periodo2: {
                    pontos: res2.data.length,
                    V: v_c2,
                    I: i_c2,
                    P: p_c2,
                    KWH: kwh_c2,
                    eletric: {
                        v_2: {
                            max: res2.Vef_max,
                            min: res2.Vef_min,
                            med: res2.Vef_med,
                        },
                        i_2: {
                            max: res2.Ief_max,
                            min: res2.Ief_min,
                            med: res2.Ief_med,
                        },
                        p_2: {
                            max: res2.Pef_max,
                            min: res2.Pef_min,
                            med: res2.Pef_med,
                        },
                        c_2: {
                            total: res2.Kwh_total
                        }
                    },
                },
            });
        }

        if ((board.canal === 4) || (board.canal === 5) || (board.canal === 6) || (board.canal === 7)) //combinações que incluem canal 3
        {
            var res3 = await getChartsOfBoard({
                deviceid: board.reference,
                channel: 3,
                dt_start: dt_start.getTime(),
                dt_end:   dt_end.getTime()
            });
    
            if(res3 === [] || !Array.isArray(res3.data)){
                alert("sem dados disponiveis no canal 3");
                return 0;
            }
            else
            {
                for(let ponto of res3.data){
                    v_c3.push([ponto.timestamp, ponto.v_c3]);
                    i_c3.push([ponto.timestamp, ponto.i_c3]);
                    p_c3.push([ponto.timestamp, ponto.p_c3]);
                    kwh_c3.push([ponto.timestamp, ponto.kwh_c3]);
                }
            }
            this.setState({
                periodo3: {
                    pontos: res3.data.length,
                    V: v_c3,
                    I: i_c3,
                    P: p_c3,
                    KWH: kwh_c3,
                    eletric: {
                        v_3: {
                            max: res3.Vef_max,
                            min: res3.Vef_min,
                            med: res3.Vef_med,
                        },
                        i_3: {
                            max: res3.Ief_max,
                            min: res3.Ief_min,
                            med: res3.Ief_med,
                        },
                        p_3: {
                            max: res3.Pef_max,
                            min: res3.Pef_min,
                            med: res3.Pef_med,
                        },
                        c_3: {
                            total: res3.Kwh_total
                        }
                    },
                },
            });
        }
        if (board.canal === 7) //apenas caso trifasico
        {            
            for(var i=0; i<res3.data.length; i++){
                p_ctrif.push([res3.data[i].timestamp, res1.data[i]?.p_c1+res2.data[i]?.p_c2+res3.data[i]?.p_c3]);
                kwh_ctrif.push([res3.data[i].timestamp, res1.data[i]?.kwh_c1+res2.data[i]?.kwh_c2+res3.data[i]?.kwh_c3]);
            }
            this.setState({
                periodo_trif: {
                    pontos: res3.data.length,
                    P: p_ctrif,
                    KWH: kwh_ctrif,
                },
            });
        }
    }
    async mountChartConsumo({ dt_start, dt_end }){

        //const { board } = this.props.match.params;
        const board = this.context[0].device.device.reference;

        var res = await getConsumo({
            dt_start: moment().subtract(1,'month').format(),
            dt_end:   new Date().toISOString(),
            deviceid: board, 
        });

        var channel1 = [], channel2 = [], channel3 = [];
        
        
        switch (this.context[0].device.device.canal) {
            case 1:
                for(let dia of res){
                    /*moment(new Date(dia.timestamp.split('.')[0]).getTime()).tz("America/Araguaina").valueOf()*/
                    channel1.push([ new Date(dia.timestamp.split('.')[0]).getTime(), dia.KWh_dia_CH1]);
                }    
            break;
            case 2:
                for(let dia of res){
                    channel2.push([ new Date(dia.timestamp.split('.')[0]).getTime(), dia.KWh_dia_CH2]);
                }
            break;
            case 3:
                for(let dia of res){
                    channel3.push([ new Date(dia.timestamp.split('.')[0]).getTime(), dia.KWh_dia_CH3]);
                }
            break;
            default:
            break;
        }



        this.setState({
            consumo: {
                consumo: channel1,
            }
        })
    }

    fourier({onda_Vca, onda_Ica}) {
        var len = onda_Vca.length;
        var output = new Array();

        //15 primeiras harmônicas. ir até no maximo len/2
        for(let k = 0; k < 15; k++) {
            var real_Vca = 0, real_Ica = 0;
            var imag_Vca = 0, imag_Ica = 0;

            for(let n = 0; n < len; n++) {
                real_Vca += onda_Vca[n] * Math.cos(-2 * Math.PI * k * n / len);
                imag_Vca += onda_Vca[n] * Math.sin(-2 * Math.PI * k * n / len);
                real_Ica += onda_Ica[n] * Math.cos(-2 * Math.PI * k * n / len);
                imag_Ica += onda_Ica[n] * Math.sin(-2 * Math.PI * k * n / len);
            }

            var modulo_Vca = Math.sqrt(real_Vca * real_Vca + imag_Vca * imag_Vca);
            var angulo_Vca = Math.atan2(imag_Vca, real_Vca); 
            var modulo_Ica = Math.sqrt(real_Ica * real_Ica + imag_Ica * imag_Ica);
            var angulo_Ica = Math.atan2(imag_Ica, real_Ica); 
            
            output.push([k, modulo_Vca/(len/2), angulo_Vca*180/Math.PI, modulo_Ica/(len/2), angulo_Ica*180/Math.PI]);
        }
        
        return output;
    }
    
    async componentDidMount(){
        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");
        this.changePicker(start, end);

        Auth.currentCredentials().then((info) => {
            let config = {};
            let client;

            config.host = "a23kawqisrluu3-ats.iot.us-east-2.amazonaws.com";
            config.protocol = 'wss';
            config.clientId = `client-${Math.floor((Math.random() * 100000) + 1)}`;
            config.accessKeyId  = info.accessKeyId;
            config.secretKey    = info.secretAccessKey;
            config.sessionToken = info.sessionToken;

            client = AwsIot.device(config);

            client.on('connect', () => {
                console.group("connect event");
                console.log("CONNECTED");
                // console.log(this.props);
                console.log(this.context);
                console.log(this.context[0].device);
                console.log(this.context[0].device.device.topic);
                console.groupEnd();

                client.subscribe(this.context[0].device.device.topic+'eletric/data');

                if(!this.state.MqttClient){
                    this.setState({
                        MqttClient: client
                    })
                }
            });
            client.on('message', (topic, message) => {
                console.log(topic, JSON.parse(message))

                message = JSON.parse(message);

                if (message.e_timestamp) //no novo formato
                {
                    this.setState({
                        tempoReal: {
                            v_1: message.e_ch_1.v,
                            i_1: message.e_ch_1.i,
                            p_1: message.e_ch_1.p,
                            vca_onda_1: message.e_ch_1.v_w,
                            ica_onda_1: message.e_ch_1.i_w,
                            v_2: message.e_ch_2.v,
                            i_2: message.e_ch_2.i,
                            p_2: message.e_ch_2.p,
                            vca_onda_2: message.e_ch_2.v_w,
                            ica_onda_2: message.e_ch_2.i_w,
                            v_3: message.e_ch_3.v,
                            i_3: message.e_ch_3.i,
                            p_3: message.e_ch_3.p,
                            vca_onda_3: message.e_ch_3.v_w,
                            ica_onda_3: message.e_ch_3.i_w,
                        },
                    })
                    console.log("message.monofasico novo formato");
                }
                if (message.monofasico) //manter até eliminar FW antigo de todas as pcbs
                {
                    this.setState({
                        tempoReal: {
                            v_1: message.monofasico.canal_1.V,
                            i_1: message.monofasico.canal_1.I,
                            p_1: message.monofasico.canal_1.P,
                            vca_onda_1: message.monofasico.canal_1.onda.Vca,
                            ica_onda_1: message.monofasico.canal_1.onda.Ica,
                        },
                    })
                    console.log("message.monofasico");
                }
                if (message.trifasico)
                {
                    this.setState({
                        tempoReal: {
                            v_1: message.trifasico.canal_1.V,
                            i_1: message.trifasico.canal_1.I,
                            p_1: message.trifasico.canal_1.P,
                            vca_onda_1: message.trifasico.canal_1.onda.Vca,
                            ica_onda_1: message.trifasico.canal_1.onda.Ica,
                            v_2: message.trifasico.canal_2.V,
                            i_2: message.trifasico.canal_2.I,
                            p_2: message.trifasico.canal_2.P,
                            vca_onda_2: message.trifasico.canal_2.onda.Vca,
                            ica_onda_2: message.trifasico.canal_2.onda.Ica,
                            v_3: message.trifasico.canal_3.V,
                            i_3: message.trifasico.canal_3.I,
                            p_3: message.trifasico.canal_3.P,
                            vca_onda_3: message.trifasico.canal_3.onda.Vca,
                            ica_onda_3: message.trifasico.canal_3.onda.Ica,
                            p_trif: message.trifasico.canal_1.P+message.trifasico.canal_2.P+message.trifasico.canal_3.P,
                        },
                    })
                }
                
            });
        });
    }

    async changePicker(startDate, endDate){
        let dt_start = new Date(startDate);
        let dt_end = new Date(endDate);

        this.mountChartPrincipal({ dt_start, dt_end });
        this.mountChartConsumo({ dt_start, dt_end });

        this.setState({
           picker: {
               start: startDate, 
               end: endDate,
           }
        });
    }
    
    tipoVisGrafDetalhes = (event) => {
        // this.setState({
        //     tipoAgrupGraf: event.target?.value
        // })
        // console.log(`tipoAgrupGraf: ${event.target.value}`);
        // if(tipoAgrupGraf==="2") {
        //     // document.getElementById('tipoAgrupGraf').style.display = 'block';
        //     document.getElementById('tipoAgrupGraf').style.display = 'none';
        //  }
    }
    tipoGrandezaDetalhes(event) {
        // this.setState({
        //     tipoGrandeza: event.target?.value,
        // });
        // console.log(`tipoGrandeza: ${event.target.value}`);
    }
    qualCanalDetalhes(event) {
        // this.setState({
        //     qualCanal: event.target.value,
        // })
        // console.log(`qualCanal: ${event.target.value}`);
    }
    

    render(){
        let now = new Date();
        let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0));
        let end = moment(start).add(1, "days").subtract(1, "seconds");

        let ranges = {
            "1h": [moment().subtract(1,'hour'),moment()],
            "Hoje": [moment().startOf('day'),moment()],
            "Ontem": [(moment().startOf('day')).subtract(1,'days'),moment().startOf('day')],
            "Últimos 3 dias": [(moment().startOf('day')).subtract(3,'days'),moment()],
            "Últimos 7 dias": [(moment().startOf('day')).subtract(7,'days'),moment()],
            "Últimos 30 dias": [(moment().startOf('day')).subtract(1,'month'),moment()],
            "Mês atual": [moment().startOf('month'),moment()]
        }
        let local = {
            "format":"DD-MM-YYYY HH:mm",
            "sundayFirst" : false
        }
        let maxDate = moment(start).add(24, "hour");

        function ToggleButtonExample() {
            const [checked, setChecked] = React.useState('checked');//useState(false);
            const [radioValue, setRadioValue] = [];//useState('1');
          
            const radios = [
              { name: 'Active', value: '1' },
              { name: 'Radio', value: '2' },
              { name: 'Radio', value: '3' },
            ];
          
            return (
              <>
                <ButtonGroup toggle className="mb-2">
                  <ToggleButton
                    type="checkbox"
                    variant="secondary"
                    checked={checked}
                    value="1"
                    onChange={(e) => setChecked(e.currentTarget.checked)}
                  >
                    Checked
                  </ToggleButton>
                </ButtonGroup>
                <br />
                <ButtonGroup toggle>
                  {radios.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      type="radio"
                      variant="secondary"
                      name="radio"
                      value={radio.value}
                      checked={radioValue === radio.value}
                      onChange={(e) => setRadioValue(e.currentTarget.value)}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </>
            );
        }
          
        //   render(<ToggleButtonExample />);

        return (
            <Col className="text-center text-md-left" >
                {/* <Row>
                    <Col>
                        <Switch 
                            tamanho="small"
                            state={this.state.tempoReal.p_1 >= LIMIAR_LIGADO_DESLIGADO} 
                            onClick={()=>{
                                if(this.state.MqttClient){
                                    if(this.state.tempoReal.p_1 >= LIMIAR_LIGADO_DESLIGADO){
                                        this.state.MqttClient.publish(this.context[0].device.device.topic+'cmd/ir0', JSON.stringify({"cmd": "off"}));
                                    }else{
                                        this.state.MqttClient.publish(this.context[0].device.device.topic+'cmd/ir0', JSON.stringify({"cmd": "on"}));
                                    }
                                }
                            }}
                        />
                    </Col>
                </Row> */}
                {/* <Row>
                    <Col>
                        <Button 
                            style={{
                                backgroundColor: 'green',
                                borderColor: 'green'
                            }}
                            onClick={() => {
                                this.state.MqttClient.publish(this.context[0].device.device.topic+'cmd/ir0', JSON.stringify({"cmd": "on"}));
                            }}
                        >Ligar</Button>
                    </Col>
                    <Col>
                        <Button 
                            style={{
                                backgroundColor: 'red',
                                borderColor: 'red'
                            }}
                            onClick={() => {
                                this.state.MqttClient.publish(this.context[0].device.device.topic+'cmd/ir0', JSON.stringify({"cmd": "off"}));
                            }}
                        >Desligar</Button>
                    </Col>
                </Row> */}
                <br/>
                <Row>
                    <Col>
                        <p>Selecione o intervalo</p>
                    </Col>
                    <Col>
                        <DateTimeRangeContainer 
                            ranges={ranges}
                            start={this.state.picker.start}
                            end={this.state.picker.end}
                            local={local}
                            maxDate={maxDate}
                            applyCallback={this.changePicker}
                        >    
                            <FormControl
                                id="formControlsTextB"
                                type="text"
                                label="Text"
                                placeholder={`${start.toISOString()} - ${end.toISOString()}`}
                            /> 
                        </DateTimeRangeContainer>
                    </Col>
                    <Col/><Col/>
                </Row>
                <br/>
                <Container>
                    <h3>Unidade: {this.context[0].device.device.name} </h3>
                    <br/>
                    <h3>Resultado do período selecionado ( {this.state.periodo1.pontos} pontos):</h3>
                    <p>Tensão máxima/minima: {this.state.periodo1.eletric.v_1.max.toFixed(2)} V / {this.state.periodo1.eletric.v_1.min.toFixed(2)} V</p>
                    <p>Tensão média: {this.state.periodo1.eletric.v_1.med.toFixed(2)} V</p>
                    <p>Tensão atual: {this.state.tempoReal.v_1.toFixed(2)} V</p>
                    <br/>
                    <p>Corrente máxima/minima: {this.state.periodo1.eletric.i_1.max.toFixed(2)} A / {this.state.periodo1.eletric.i_1.min.toFixed(2)} A</p>
                    <p>Corrente média: {this.state.periodo1.eletric.i_1.med.toFixed(2)} A</p>
                    <p>Corrente atual: {this.state.tempoReal.i_1.toFixed(2)} A</p>
                    <br/>
                    <p>Potência máxima/minima: {this.state.periodo1.eletric.p_1.max.toFixed(2)} W / {this.state.periodo1.eletric.p_1.min.toFixed(2)} W</p>
                    <p>Potência média: {this.state.periodo1.eletric.p_1.med.toFixed(2)} W</p>
                    <p>Potência atual (canal 1): {this.state.tempoReal.p_1.toFixed(2)} W</p>
                    <br/>
                    <p>Potência atual (trifásica): ({(this.state.tempoReal?.p_1 + this.state.tempoReal?.p_2 + this.state.tempoReal?.p_3).toFixed(2)}) W</p>
                    <br/>
                    <p>Energia do período: {this.state.periodo1.eletric.c_1.total.toFixed(2)} KWh</p>
                    <p>Custo aproximado: R${(this.state.periodo1.eletric.c_1.total * 1.04).toFixed(2)}</p>
                    <br/>
                    <div onChange={this.tipoVisGrafDetalhes}>
                        <input type="radio" value="1" name="tipoAgrupGraf" onClick={() => this.setState({ tipoAgrupGraf: "1" })}/> {' '} Apenas potência total <br/>
                        <input type="radio" value="2" name="tipoAgrupGraf" onClick={() => this.setState({ tipoAgrupGraf: "2" })}/> Agrupar por grandeza 
                        {
                            this.state.tipoAgrupGraf === "2" ?
                            <div onChange={this.tipoGrandezaDetalhes}>
                                <input type="radio" value="1" name="tipoGrandeza" onClick={() => this.setState({ tipoGrandeza: "1" })} /> Tensão <br/>
                                <input type="radio" value="2" name="tipoGrandeza" onClick={() => this.setState({ tipoGrandeza: "2" })} /> Corrente <br/>
                                <input type="radio" value="3" name="tipoGrandeza" onClick={() => this.setState({ tipoGrandeza: "3" })} /> Potência <br/>
                            </div>
                            : <div><br/></div>
                        }
                        <br/>
                        <input type="radio" value="3" name="tipoAgrupGraf" onClick={() => this.setState({ tipoAgrupGraf: "3" })}/> Agrupar por canal <br/>
                        {
                            this.state.tipoAgrupGraf === "3" ?
                            <div onChange={this.qualCanalDetalhes}>
                                <input type="radio" value="1" name="qualCanal" onClick={() => this.setState({ qualCanal: "1" })} /> Fase A <br/>
                                <input type="radio" value="2" name="qualCanal" onClick={() => this.setState({ qualCanal: "2" })} /> Fase B <br/>
                                <input type="radio" value="3" name="qualCanal" onClick={() => this.setState({ qualCanal: "3" })} /> Fase C <br/>
                            </div>
                            : <div><br/></div>
                        }                        
                        <input type="radio" value="4" name="this.state.tipoAgrupGraf" onClick={() => this.setState({ tipoAgrupGraf: "4" })} /> Agrupar todos <br/>
                    </div>
                </Container>
                <br/>

                <HighchartsReact 
                   highcharts={Highcharts} 
                   allowChartUpdate = { true }
                   options={this.consumoChart({
                       title: "Consumo diário no período",
                       consumo: this.state.consumo.consumo
                   })} 
                />
                <br/>
                {
                    this.context[0].device.device.canal === 1 ?
                    // this.state.tipoAgrupGraf === 1 ?
                        <HighchartsReact 
                            highcharts={Highcharts} 
                            allowChartUpdate = { true }
                            options={this.principalChart({
                                title: "Consumo detalhado",

                                V_CH1: this.state.periodo1.V,
                                I_CH1: this.state.periodo1.I,
                                P_CH1: this.state.periodo1.P,
                                KWH_CH1: this.state.periodo1.KWH,
                            })}
                        />           
                    : <div></div>  
                }
                {
                    this.context[0].device.device.canal === 7 ?
                    // this.state.tipoAgrupGraf === 1 ?
                        <HighchartsReact 
                            highcharts={Highcharts} 
                            allowChartUpdate = { true }
                            options={this.principalChart({
                                title: "Consumo detalhado",
                                // quantidade: this.context[0].device.device.length,

                                V_CH1: this.state.periodo1.V,
                                I_CH1: this.state.periodo1.I,
                                P_CH1: this.state.periodo1.P,
                                KWH_CH1: this.state.periodo1.KWH,

                                V_CH2: this.state.periodo2.V,
                                I_CH2: this.state.periodo2.I,
                                P_CH2: this.state.periodo2.P,
                                KWH_CH2: this.state.periodo2.KWH,

                                V_CH3: this.state.periodo3.V,
                                I_CH3: this.state.periodo3.I,
                                P_CH3: this.state.periodo3.P,
                                KWH_CH3: this.state.periodo3.KWH,

                                P_CHtrif: this.state.periodo_trif.P,
                            })}
                        />           
                    : <div></div>         
                }
                <br/>
                { 
                    this.state.onda1.vca.length>0 ? 
                        <div>
                            <Row>
                                <Col>
                                    <HighchartsReact 
                                        highcharts={Highcharts} 
                                        options={this.ondasChart({
                                            title: "Análise temporal", 
                                            vca: this.state.onda1.vca, 
                                            ica: this.state.onda1.ica,
                                        })} 
                                    />
                                </Col>
                                <Col>
                                    <HighchartsReact 
                                        highcharts={Highcharts} 
                                        options={this.polarChart({ 
                                            modulo_Vca: this.state.onda1.modulo_Vca[1][1] || 0, 
                                            angulo_Vca: this.state.onda1.angulo_Vca[1][1] || 0, 
                                            modulo_Ica: this.state.onda1.modulo_Ica[1][1] || 0, 
                                            angulo_Ica: this.state.onda1.angulo_Ica[1][1] || 0,
                                        })} 
                                    />
                                </Col>
                            </Row>
                            <br/>
                            <HighchartsReact 
                                highcharts={Highcharts} 
                                options={this.dftChart({ 
                                    title: "Análise harmônica", 
                                    modulo_Vca: this.state.onda1.modulo_Vca,
                                    modulo_Ica: this.state.onda1.modulo_Ica,
                                })} 
                            />
                        </div>
                        : <div></div>
                }
            </Col>
        );
    }

};

export default DeviceCharts;