
import ApexCharts from 'apexcharts';
import { Auth } from 'aws-amplify';
import AwsIot from 'aws-iot-device-sdk';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import React, { Component } from 'react';
import truck_green_temp_blue from '../images/truck_green_temp_blue.png';
import truck_green_temp_red from '../images/truck_green_temp_red.png';
import truck_green_temp_yellow from '../images/truck_green_temp_yellow.png';
import truck_red_temp_blue from '../images/truck_red_temp_blue.png';
import truck_red_temp_red from '../images/truck_red_temp_red.png';
import truck_red_temp_yellow from '../images/truck_red_temp_yellow.png';

// import data from "./data.json";

const mapStyles = {
  width: '100%',
  height: '100%'
};

const MapaCentro = 
{
    lat: -10.2004,
    lng: -48.3233
};

export class MapContainer extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            stores: [],
            // options: {
            //   chart: {
            //     id: 'apexchart-example'
            //   },
            //   xaxis: {
            //     categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
            //   }
            // },
            // series: [{
            //   name: 'series-1',
            //   data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
            // }]
        }
    }

    state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        temperaturas: {},
        lat: {},
        lng: {},
        currentBoardSelected: {
            temperatures: []
        },
        stores: [],
        temperaturaParaOChart: 0
    };
    
    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }
    
    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }
    };
      // onMapClickedOut = (props) => {
      //   if (this.state.showingInfoWindow) {
      //     this.setState({
      //       showingInfoWindow: false,
      //       activeMarker: null
      //     })
      //   }
      // };
    // createMarkers() {
    //     if(this.state.render === false){
    //      this.createMarkers();
    //     }
    //     return this.state.locations.map( (item ,index) => {
    //      return <Marker
    //        position={{lat: item.lat, lng: item.lng}}
    //        key={index}
    //       />
    //     })
    //    }

    // createInfoWindow = () => {
    //   let contentString = ReactDOMServer.renderToString(
    //     <GaugeChart id="gauge-chart3" 
    //             nrOfLevels={30} 
    //             colors={["#FF5F6D", "#FFC371"]} 
    //             arcWidth={0.3} 
    //             percent={0.37} 
    //             style={chartStyle}
    //           />
    //     );
    //   return new google.maps.InfoWindow({
    //     map: this.map,
    //     anchor: this.marker,
    //     content: contentString
    //   })
    // }

    displayMarkers = () => {
      // - próxima missão: mostrar 2 ou mais markers na tela, de forma simultânea 
      // - apenas 1 infowindow de cada vez
      return this.state.stores.map((store, index) => {
        let icon_path;
        const dateTime = new Date().getTime();
        const timestamp = Math.floor(dateTime / 1000);
        // console.log(store.temps[0].value);
        // console.log(store.timestamp);
        // console.log(timestamp);
        if (store.timestamp > (timestamp - 5*60)) //timestamp_atual - 5min
        {
          if (store.temps[0].value > 0) 
            icon_path = truck_green_temp_red
          else if ((store.temps[0].value <= 0) && (store.temps[0].value > -5))
            icon_path = truck_green_temp_yellow
          else 
            icon_path = truck_green_temp_blue
        }
        else //ideia é que o caminhão fique vermelho se a pultima leitura for a mais de 5 min
        {
          if (store.temps[0].value > 0) 
            icon_path = truck_red_temp_red
          else if ((store.temps[0].value <= 0) && (store.temps[0].value > -5))
            icon_path = truck_red_temp_yellow
          else 
            icon_path = truck_red_temp_blue
        }
        // new google.maps.Marker({position: {lat: -10, lng: -48}, map: map});
        
        // console.log(icon_path);

        return  <Marker 
            key={index} 
            id={index} 
            name={'Pão da Hora_OLN5280' } 
            // name={'Pão da Hora_'+index + ' ' + formatted} 
            // animation={this.props.google.maps.Animation.DROP}
            icon={{ 
              url: icon_path,
              scaledSize: { 
                width: 40,
                height: 40
              }
            }}
            position={{
              lat: store.lat,
              lng: store.long
            }}
            onClick={(props, marker, e) => {
              this.onMarkerClick(props, marker, e);
              this.setState({
                currentBoardSelected: 0
              });
            }} 
          />
      })
    }
    displayTemps = () => {
      if(this.state.stores?.length > 0) {
        if(this.state.stores[0].temps.length > 0) {
          return this.state.stores[0].temps.map((elm, index) => {
                var formatted = new Intl.DateTimeFormat('pt-BR', { //não é pra ser a hora atual. Deve ser store.timestamp aqui
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: false,
                    // timeZone: 'UTC'
                }).format(this.state.stores.timestamp);

                return (
                    <p key={index}>
                        {/* {formatted + ': ' + elm.name + ' = ' + elm.value+' °C'} */}
                        {formatted}
                    </p>
                )
          })
        }
      }
    }

    async componentDidMount(){
        // let now = new Date();
        // let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0,0,0,0));
        // let end = moment(start).add(1, "days").subtract(1, "seconds");
        // this.changePicker(start, end);

        Auth.currentCredentials().then((info) => {
            let config = {};
            let client;

            config.host = "a23kawqisrluu3-ats.iot.us-east-2.amazonaws.com";
            config.protocol = 'wss';
            config.clientId = `client-${Math.floor((Math.random() * 100000) + 1)}`;
            config.accessKeyId  = info.accessKeyId;
            config.secretKey    = info.secretAccessKey;
            config.sessionToken = info.sessionToken;

            client = AwsIot.device(config);

            client.on('connect', () => {
                console.group("connect event");
                console.log("CONNECTED");
                // // console.log(this.props);
                // console.log(this.context);
                // // console.log(this.context[0].device);
                // // console.log(this.context[0].device.device.topic);
                console.groupEnd();

                // client.subscribe('Simemap/+/+/+/+/resp/temperaturas');//provisorio
                client.subscribe('Simemap/+/+/+/+/resp/foodtrack');

                if(!this.state.MqttClient){
                    this.setState({
                        MqttClient: client
                    })
                }
            });
            client.on('message', (topic, message) => {
                message = JSON.parse(message);
                // console.log(topic,message)  
                let mensagemRecebida = {};
                // if (topic=='Simemap/MarcusAndre/7/10/smb1vx1/resp/temperaturas')
                // {//retirar esse if assim que atualizar firmware no caminhão, deixar o else
                //   mensagemRecebida = {
                //     NameDevice: message.NameDevice,
                //     long: message.CGPSINFO.longitude,
                //     lat: message.CGPSINFO.latitude,
                //     alt: message.CGPSINFO.altitude,
                //     timestamp: message.timestamp,
                //     sensores: message.temperatures,
                //     // velocity: message.velocity*1.852, //1.852: if GPS velocity in knots/s
                //     velocity: message.CGPSINFO.velocity,
                //     icon_path: [],
                //     temps: []
                //   }
                // }
                // else
                // {
                  mensagemRecebida = {
                    NameDevice: message.NameDevice,
                    long: message.longitude,
                    lat: message.latitude,
                    alt: message.altitude,
                    timestamp: message.timestamp,
                    // sensores: message.temper_sensors,
                    t_0: message.temper_values,
                    // velocity: message.velocity*1.852, //1.852: if GPS velocity in knots/s
                    velocity: message.velocity,
                    icon_path: [],
                    temps: []
                  }
                // }
                console.log('velocidade: ' + mensagemRecebida.velocity + ' km/h')
                var n=0;
                // if (mensagemRecebida.sensores !== 0)
                // {
                //   if (topic=='Simemap/MarcusAndre/7/10/smb1vx1/resp/temperaturas')
                //   {//retirar esse if assim que atualizar firmware no caminhão, deixar o else
                //     for (let [key, value] of Object.entries(mensagemRecebida.sensores)) {
                //       n++;
                //       console.log('temperatura: ' + message.temperatures[`${key}`] + ' °C')
                //       if (message.temperatures[`${key}`])
                //         mensagemRecebida.temps = [{ name: 'sensor '+`${n}`, value: message.temperatures[`${key}`]}]
                //     }
                //   }
                //   else
                //   {
                    console.log('temperatura: ' + message.t_0 + ' °C')
                    if (message.t_0)
                        mensagemRecebida.temps = [{ name: 'sensor t_0', value: message.t_0}]
                    // for (let [key, value] of Object.entries(mensagemRecebida.t_0)) {
                    //   n++;
                    //   console.log('temperatura: ' +message.temper_sensors[`${key}`]+ ': ' + message.temper_values[`${key}`] + ' °C')
                    //   if (message.temper_values[`${key}`])
                    //     mensagemRecebida.temps = [{ name: 'sensor '+message.temper_sensors[`${key}`], value: message.temper_values[`${key}`]}]
                    // }
                  // }
                // }
                else
                  mensagemRecebida.temps = [{ name: 'sensor ausente', value: -50}]
          
                // console.log(topic,message)  
                this.setState({
                  stores: [ mensagemRecebida ]
                }) 
            });
        });
    }

  render() {  
    const {google} = this.props;
    const {showInfoWindow, activeMarker} = this.state;

    const max = 50
    const min = -30
    function valueToPercent (value) {
      return ((value-min) * 100) / (max-min)
    } 

    setTimeout(() => {
        if(this.state.showingInfoWindow) {
            // internalMapContainer = document.querySelector(".gm-style-iw-d");
            var chart_term = new ApexCharts(document.querySelector('#div_chart_term'), {
                chart: {
                    height: '100%',
                    id: 'radialBar',
                    type: "radialBar",
                },
                series: [
                    // this.state?.stores[0]?.temps[0]?.value
                    valueToPercent(this.state?.stores[0]?.temps[0]?.value)
                ],
                colors: ["#4486E8"],
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: '#EFEFEF',
                            startAngle: -90,
                            endAngle: 90,
                        },
                        dataLabels: {
                            name: {
                                show: false,
                            },
                            value: {
                                fontSize: "20px",
                                show: true,
                                formatter: (p) => `${this.state?.stores[0]?.temps[0]?.value} ˚C`
                            }
                        }
                    }
                },
                fill: {
                    type: "gradient",
                    gradient: {
                        shade: "dark",
                        type: "horizontal",
                        gradientToColors: ["#F52C16"],
                        // stops: [-20, 150]
                    }
                },
                stroke: {
                    lineCap: "butt"
                },
                labels: ["Progress"]
            });
            chart_term.render()
        }
    }, 500)
    
    
    if (!this.props.loaded) return <div>Loading...</div>;
    return (
        <Map id="map_canvas"
          google={this.props.google}
          // data={locais}
          streetViewControl={false}
          fullscreenControl={false}
          zoom={13}
          style={mapStyles}
          initialCenter={MapaCentro}
          onClick={this.onMapClicked}
          // onMouseover={this.onMarkerClick}
          // onMouseout={this.onMapClicked}
          // // onMouseover={this.onMarkerClick}
          // onMouseover={() => console.log('mouseover')}
          // onMouseout={() => console.log('mouseout')}
          // // onMouseout={this.onMapClicked}
          >
            {/* <Chart options={this.state.options} series={this.state.series} type="bar" width={500} height={320} />  */}
            {/* <Marker onClick={this.onMarkerClick} name={'Current location'} /> */}
            {/* <Marker position={MapaCentro} onClick={this.onMarkerClick}/> */}
            {/* <Marker position={this.state.new_markers}/> */}
            {/* <Marker 
              position={MapaCentro}
              onClick={this.onMarkerClick}
            /> */}
            {/* position={{ lat: 48.00, lng: -122.00}} */}
            {this.displayMarkers()}
            {/* {this.createInfoWindow()} */}

            <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div id="infotruck">
                        <strong>{this.state.selectedPlace?.name}</strong>
                        <br/>
                        { this.displayTemps() }
                    </div>
                    
                    <div id='div_chart_term' style={{ height: '200px', width: '210px', overflow: 'hidden' }}></div>
                    {/* <div id='div_chart_veloc' style={{ height: '200px', width: '210px', overflow: 'hidden' }}></div> */}
                </div>
            </InfoWindow>
        </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCOvhAuxReFgV0FAgjhbJqFerSsSXgzXDk'
})(MapContainer);
