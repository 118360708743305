import React, { useState } from 'react';

import { Nav, NavItem, NavLink } from 'reactstrap';


import "./SideBar.css";
import classNames from "classnames";
import moment from "moment-timezone";
import { useStateValue } from '../../State';

const SideBar = () => {
    const [{ cognito }, dispatch] = useStateValue();
    const [date, setDate] = useState(moment(Date.now()).tz("America/Araguaina").format("DD-MM-YYYY"));
    const [time, setTime] = useState(moment(Date.now()).tz("America/Araguaina").format("HH:mm"));

    // dispatch({
    //     type: "change",
    //     newData: { picture: p.idToken.payload.picture}
    // });

    function updateClock(){
        setTime(moment(Date.now()).tz("America/Araguaina").format("HH:mm"))
        setDate(moment(Date.now()).format("DD-MM-YYYY"))
        setTimeout(updateClock, 60000/2);
    }
    setTimeout(updateClock, 1000);
    
    return (
        <div className={classNames("sidebar", { "is-open": true })}>
            <div className="side-menu">
                <p>Simemap_UFT</p>
                <Nav vertical className="list-unstyled pb-3">
                    <NavItem>
                        <NavLink href="/">Placas</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/home/schedules">Agendamentos</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/home/GPSMapTrack">Rastreamento</NavLink>
                    </NavItem>
                </Nav>
                <hr style={{border: "1px solid white"}}/>
                <p>
                    {
                        time
                    }    
                </p>
                <p>
                    {
                        date
                    }    
                </p>
            </div>
        </div>
    );
}

export default SideBar;