import classNames from "classnames";
import { Container } from 'reactstrap';
import React, { Component } from 'react';

import { createBrowserHistory } from "history";
import { Switch, Router, Route, Redirect } from "react-router-dom";

import SideBar from "./SideBar/SideBar";
import HomePage from "../pages/HomePage";
import SchedulesPage from "../pages/SchedulesPage";
import GPSMapTrack from "../pages/GPSMapTrack";
import TopBar from '../components/TopBar/TopBar';

import { Auth } from "aws-amplify";
import { StateContext } from "../State";

import "./../App.css";
import CognitoReactSignInForm from "../pages/CognitoReactSignInForm";
import { getMyBoards } from "../services/api";
import DeviceCharts from "./DeviceCharts/DeviceCharts";

const hist = createBrowserHistory();

class Content extends Component {
    static contextType = StateContext;

    state = {
        cognito: this.context[0].cognito,
        
        isLogued: false,
        isLoading: true,
    }

    setContext({ username, picture, email }){
        this.context[0].cognito = { username, picture, email };
        this.setState({...this.state});
    }

    async componentDidMount(){
        // console.log("montei")
        await Auth.currentSession()
            .then((p) => {
                console.group("main");
                
                console.group("Auth");
                console.log(p.idToken.payload);
                console.log(p.idToken.payload['cognito:username']);
                console.groupEnd();

                console.group("context");
                console.log(this.context)
                console.groupEnd();

                console.group("state");
                console.log(this.state);
                console.groupEnd();

                if(this.state.cognito.picture === null){
                    this.setContext({ picture: p.idToken.payload.picture, email: p.idToken.payload.email, username: p.idToken.payload['cognito:username'] });
                    console.log("picture: " + p.idToken.payload.picture);
                }

                this.setState({ isLogued: true });
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                console.groupEnd();
                this.setState({ isLoading: false });
            });

        // console.log(this.context);
    }

    render() {
        if(!this.state.isLogued && !this.state.isLoading){
            return (
                (createBrowserHistory()).push('/login')
                // <CognitoReactSignInForm />
            )
        }

        return (
            <div className="App wrapper">
                <SideBar/>
                
                <Container fluid className={classNames("content", { "is-open": true })}>
                    <TopBar/>
                    <Switch>
                        <Route exact path="/home/devices" component={HomePage} />
                        <Route exact path="/home/devices/:board" render={(props) => <DeviceCharts {...props}/>} />
                        
                        <Route exact path="/home/schedules" component={SchedulesPage} />
                        <Route exact path="/home/GPSMapTrack" component={GPSMapTrack} />
                    </Switch>
                </Container>
            </div>
        );
    }
}

export default Content;