const { API } = require("aws-amplify");


async function getMyBoards(){
    var res = await API.get('SimemapTenants', `/devices`);
    return [...res.res.tenant, ...res.res.user];
}

async function getChartsOfBoard({deviceid, channel, dt_start, dt_end}){

    console.log(`${dt_start} => ${new Date(dt_start)}`);
    console.log(`${dt_end} => ${new Date(dt_end)}`);

    var res = await API.get('SimemapTenants', `/consumo/eletrico/c${channel}?device_id=${deviceid}&dt_start=${dt_start}&dt_end=${dt_end}`);
    // var res = await API.get('SimemapTenants', `/consumo/eletrico?device_id=${deviceid}&dt_start=${dt_start}&dt_end=${dt_end}&canais=${channel.join(',')}`);

    console.log("-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=")
    console.log(res);
    console.log("-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=")
    
    return res ? res.res : [];
}
async function getOnda({deviceid, channel, dt}){
    var res = await API.get('SimemapTenants', `/consumo/eletrico/onda/${channel}?device_id=${deviceid}&dt=${dt}`);
    console.log(res);
    
    return res.res;
}

async function getConsumo({deviceid, dt_start, dt_end}){
    var res = await API.get('SimemapTenants', `/consumo/eletrico/diario?deviceID=${deviceid}&dt_start=${dt_start}&dt_end=${dt_end}`);
    console.log("getConsumo");
    console.log(res);
    
    return res != null ? res.res : [];
}

async function getSchedules({dt_start, dt_end}){
    var res = await API.get(
        'SimemapTenants', 
        `/devices/schedule?dt_start=${dt_start}&dt_end=${dt_end}`
    );
    console.log("getSheduleActions");
    console.log(res);

    return res.res;
}
async function insertSchedule({thingID, actionID, time}){
    var body = {
        schedules: [
            {time, thingID, actionID}
        ]
    };
    console.log(body);
    var res = await API.post('SimemapTenants',  '/devices/schedule', {body});
    console.log("insertSchedule");
    console.log(res)

    return res.res.res === "sucess" ? true : false;
}

async function getActionsType(){
    var res = await API.get('SimemapTenants', '/devices/actions');

    console.log("getActionsType");
    console.log(res);

    return res.res
}

export {
    getMyBoards,
    getChartsOfBoard,
    getConsumo,
    getOnda,
    getSchedules,
    insertSchedule,
    getActionsType,
}