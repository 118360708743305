import React from 'react';

import './Switch.css';

const Switch = ({state, onClick, tamanho = "large"}) => {
    // var classe = "rocker" + (tamanho === "small") ? " rocker-small" : "";
    var classe = "rocker";
    if(tamanho === "small"){
        classe += " rocker-small";
    }
    
    return (
        <div class="mid">
            <label class={classe} >
                <input 
                    type="checkbox" 
                    checked={state}
                    onClick={()=>{
                        onClick();
                    }}
                />
                <span class="switch-left">On</span>
                <span class="switch-right">Off</span>
            </label>
        </div>
    );
};

export default Switch;