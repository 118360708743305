import "@fullcalendar/core/main";
import dayGridPlugin from "@fullcalendar/daygrid";
import "@fullcalendar/daygrid/main";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import "@fullcalendar/timegrid/main";
import moment from "moment-timezone";
import React, { Component } from 'react';
import { Field, Form } from "react-final-form";
import { Button, Col, Container, FormGroup, Input, Label, Modal, ModalBody, Row, UncontrolledTooltip } from 'reactstrap';
import { getActionsType, getMyBoards, getSchedules, insertSchedule } from '../services/api';

class SchedulesPage extends Component {
    constructor(props){
        super(props);
        // this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    state = {
        events: [],
        things: [],
        actions: [],
        modalAddSchedule: false,
    }

    async componentDidMount(){
        this.getEvents();
    }
    getEvents = async () => {
        var res = await getSchedules({ 
            dt_start: new Date("10-10-2002 20:00:00").getTime(), 
            dt_end: new Date("10-10-2020 20:00:00").getTime()
        });
        
        let events  = [];

        for(let element of res){
            console.log(element.action_time);
            console.log(new Date(element.action_time.split('.')[0]));
            //console.log(moment(new Date(element.action_time).getTime()).tz("America/Araguaina").format("YYYY-MM-DDTHH:MM:DD"));
            events.push({
                id: element.id,
                description: "uma descricao",
                title: element.name + ' ' + element.action,
                start: element.action_time.split('.')[0],
                //date: moment( (new Date().setDate(new Date().getDate() + i)) ).format("YYYY-MM-DD"),
                //end: moment( (new Date().setDate(new Date().getDate() + i)) ).format("YYYY-MM-DD"),
            });
        }
        
        this.setState({
            events,
            modalAddSchedule: false,
        });
    }

    handleDateClick = (arg) => { // bind with an arrow function
        console.log(arg)
    }
    
    getControllabelsThings = async () => {
        var devices = await getMyBoards();
        var actions = await getActionsType();

        var things = [];
        for(let device of devices){
            things.push(...device.things.filter((thing) => thing.control == true));
        }

        this.setState({
            things: things,
            actions: actions,
        });
    }

    handlerAddNewEvent = () => {
        this.getControllabelsThings();

        this.setState({
            modalAddSchedule: true
        });
    }

    handleSubmit = async (event) => {
        console.log(event);
        //event.preventDefault();
        
        var res = await insertSchedule({
            thingID: Number(event.thingInput),
            actionID: Number(event.actionInput),
            time: new Date(event.exampleDate + ' ' + event.exampleTime).getTime()
        });

        console.log(res);
        if(res){
            this.setState({modalAddSchedule: false})
            this.getEvents();
        }
    }

    render() {
        return (
            <Row>
                <Container >
                    {/* <TableDevices devices={this.state.devices} /> */}
                    <Modal isOpen={this.state.modalAddSchedule} toggle={() => { this.setState({modalAddSchedule: false}) }}>
                        <ModalBody>
                            <Form 
                                onSubmit={this.handleSubmit}
                                validate={values => {
                                    const errors = {};
                                    
                                    if(!values.thingInput || values.thingInput == -1){
                                        errors.thingInput = "Selecione uma coisa"
                                    }
                                    if(!values.actionInput || values.actionInput == -1){
                                        errors.actionInput = "Selecione uma coisa"
                                    }
                                    if(!values.exampleDate){
                                        errors.exampleDate = "Insira uma data"
                                    }
                                    if(!values.exampleTime){
                                        errors.exampleTime = "Insira um horario"
                                    }
                                    
                                    return errors;
                                }}
                                render={({ handleSubmit, values, submitting, validating, valid }) => (
                                    <form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="thingInput">Select Thing</Label>
                                                {/* innerRef={(node) => {console.log("node");console.log(node)}} */}
                                                <Field name="thingInput">
                                                    {({input, meta}) => (
                                                        <>
                                                        <Input {...input} type="select">
                                                            <option value={-1}>Selecione uma</option>
                                                            {
                                                                this.state.things.map((thing) => <option value={thing.id}>{thing.name}</option>) 
                                                            }
                                                        </Input>
                                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                                        </>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="actionInput">Select Action</Label>
                                                <Field name="actionInput">
                                                    {({input, meta}) => (
                                                        <>
                                                        <Input {...input} type="select">
                                                            <option value={-1}>Selecione uma</option>
                                                            {
                                                                this.state.actions.map((action) => <option value={action.id}>{action.action}</option>) 
                                                            }
                                                        </Input>
                                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                                        </>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="exampleDate">Data</Label>
                                                <Field name="exampleDate">
                                                    {({input, meta}) => (
                                                        <>
                                                        <Input
                                                            {...input}
                                                            type="date"
                                                            min={moment(Date.now()).format("YYYY-MM-DD")}
                                                            placeholder="date placeholder"
                                                        ></Input>
                                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                                        </>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="exampleTime">Horario</Label>
                                                <Field name="exampleTime">
                                                    {({input, meta}) => (
                                                        <>
                                                        <Input
                                                            {...input}
                                                            type="time"
                                                            placeholder="time placeholder"
                                                        ></Input>
                                                        {meta.error && meta.touched && <span>{meta.error}</span>}
                                                        </>
                                                    )}
                                                </Field>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Button type="submit" color="primary" size="lg" block>Submit</Button>
                                    </form>
                                )}
                            />
                        </ModalBody>
                    </Modal>
                    <FullCalendar
                        //plugins={[ momentTimezonePlugin ]}
                        //timeZone='America/Araguaina'
                        //timeZone='local'
                        //timeZone='UTC'
                        customButtons={{
                            addNewEvent: {
                              text: 'Novo Evento',
                              click: () => this.handlerAddNewEvent()
                            }
                        }}
                        buttonText={{
                            today:    'hoje',
                            month:    'meses',
                            week:     'semanas',
                            day:      'dias',
                            list:     'list1'
                        }}
                        headerToolbar={{
                            center: 'title',
                            right: 'addNewEvent today prev,next',
                            left: 'dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        plugins={[dayGridPlugin, timeGridPlugin]}

                        initialView="dayGridMonth"
                        defaultView="dayGridMonth"
                        dateClick={this.handleDateClick}
                        
                        displayEventTime={true}
                        events={this.state.events}
                        eventContent={renderEventContent}
                        eventClick={this.handleDateClick}
                    />
                </Container>
            </Row>
        );
    }
}

function renderEventContent(eventInfo) {
    console.log(eventInfo);
    let id = eventInfo.event.title.split(" ").join('_') + eventInfo.timeText.replace(':', '_') + String(Math.floor(Math.random() * (1000 - 1) + 1));
    return (
        <>
        <p id={id}>
            {/* <b>{eventInfo.timeText}: </b> */}
            <b>{moment(eventInfo.event.start.getTime()).format("HH:mm")}: </b>
            <i>{eventInfo.event.title}</i>
        </p>
        <UncontrolledTooltip placement="top" target={id}>
            {eventInfo.event.title}
        </UncontrolledTooltip>
        </>
    )
}

export default SchedulesPage;