import { Auth } from 'aws-amplify';
import AwsIot from 'aws-iot-device-sdk';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table } from "reactstrap";
import { useStateValue } from '../../State';
import DeviceCharts from '../DeviceCharts/DeviceCharts';
import Switch from '../Switch/Switch';

const TableRow = ({device, history, dispatch, state, onclick, client}) => {
    let i = 1;

    const [potencia, setPotencia] = useState(0);
            
    client.subscribe(device.topic+'eletric/data');
    client.on('message', (topic, message) => {
        // console.log(topic, JSON.parse(message))

        message = JSON.parse(message);

        if(topic.indexOf(device.topic) >= 0){
            // setPotencia(message.monofasico.canal_1.P);
        }
    });

    return (
        <tr>
            <th scope="row">{i++}</th>
            <td>{device.name}</td>
            <td>
                <Switch 
                state={potencia >= 40}
                onClick={()=>{
                    if(client){
                        if(potencia >= 40){
                            client.publish(device.topic+'cmd/ir0', JSON.stringify({"cmd": "off"}));
                        }else{
                            client.publish(device.topic+'cmd/ir0', JSON.stringify({"cmd": "on"}));
                        }
                    }
                }}
                tamanho = "small"
            />
            {/* <Row>
                <Col>
                    <Button style={{
                        backgroundColor: 'green',
                        borderColor: 'green'
                    }}
                    onClick={() => {
                        client.publish(device.topic+'cmd/ir0', JSON.stringify({"cmd": "on"}));
                    }}
                    >Ligar</Button>
                </Col>
                <Col>
                    <Button style={{
                        backgroundColor: 'red',
                        borderColor: 'red'
                    }}
                    onClick={() => {
                        console.log(client);
                        client.publish(device.topic+'cmd/ir0', JSON.stringify({"cmd": "off"}));
                    }}
                    >Desligar</Button>
                </Col>
            </Row> */}
            </td>
            <td>
                <Button 
                    onClick={() => { 
                        console.group("onClick");
                        dispatch({
                            type: "changeD", 
                            newData: {device: device}
                        });
                        console.log(state);
                        console.groupEnd();
                        
                        onclick(true);
                        //history.push(`/home/devices/${device.reference}`)
                    }} 
                    style={{
                        backgroundColor: 'blue',
                        borderColor: 'blue'
                    }}
                >Graficos</Button>
            </td>
        </tr>
    )
}

const TableDevices = ({devices}) => {
    const [state, dispatch] = useStateValue();
    const [click, onclick] = useState(false);
    const [connection, setConnection] = useState(false);
    const [client, setClient] = useState();

    const history = useHistory();
    
    // console.log(devices);
    // console.log(state);

    if(click){
        console.log(state);
        return (
            <DeviceCharts/>
        )
    }

    var cliente;
    Auth.currentCredentials().then((info) => {
        let config = {};
        config.host = "a23kawqisrluu3-ats.iot.us-east-2.amazonaws.com";
        config.protocol = 'wss';
        config.clientId = `client-${Math.floor((Math.random() * 100000) + 1)}`;
        config.accessKeyId  = info.accessKeyId;
        config.secretKey    = info.secretAccessKey;
        config.sessionToken = info.sessionToken;

        cliente = AwsIot.device(config);

        cliente.on('connect', () => {
            if(!client){
                console.log("CONECTED");
                setClient(cliente);
            }
        });
    });

    
    // var temppo = [];
    // for(let device of devices) {
    //     if(device.typeName.split('_')[1][0] == 'M'){
    //         for(let thing in device.things){
    //             console.log(device.things[thing].name);
                
    //             temppo.push({
    //                 id: device.things[thing].id,
    //                 name: device.things[thing].name,
    //                 topic: device.topic,
    //                 referece: device.referece,
    //                 device: device,
    //             })
    //         }
    //     }
    // }
    
    
    if(client){
        return (
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Unidade</th>
                        <th>Controlar</th>
                        <th>Monitorar</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {createLines(devices, history, dispatch, state, onclick, client)} */}
                    {
                        devices.map((thing) => {
                            return <TableRow
                                        client={client}
                                        device={thing}
                                        dispatch={dispatch}
                                        state={state}
                                        onclick={onclick}
                                    />
                        })
                    }
                </tbody>
            </Table>
        );
    }else{
        return (
            <h2>Conectando...</h2>
        )
    }
}

export {
    TableDevices
};
