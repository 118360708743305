import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { TableDevices } from '../components/Table/TableDevices';
import { getMyBoards } from '../services/api';

class HomePage extends Component {
    /*state = {}

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        const { signedIn, username, password } = this.state;

        if (!signedIn) {
            this.setState({ isSigningIn: true });
            Auth.signIn({
                username,
                password
            }).then((cognitoUser) => {
                console.log('Signed In!');
                console.log("CognitoUser:");
                console.log(cognitoUser);

                alert("Logued");

                Auth.currentSession()
                .then((userSession) => {
                    console.log("Got user currentSession:");
                    console.log(userSession);
                    this.setState({ 
                        signedIn: true, 
                        isSigningIn: false,
                        tokenId: userSession.idToken.jwtToken,
                        refreshToken: userSession.refreshToken.token
                    });
                })
                .catch((err) => {
                    this.setState({ isSigningIn: false });
                    console.log(err)
                });

            }).catch((err) => {
                this.setState({ isSigningIn: false });
                console.log(err)
            });
        }
    }

    changeAuthStorageConfiguration(e) {
        const shouldRememberUser = e.target.checked;
        if (shouldRememberUser) {
            const localStorageCache = Cache.createInstance({
                keyPrefix: "localStorageAuthCache",
                storage: window.localStorage
            });

            Auth.configure({
                storage: localStorageCache
            });
        } else {
            const sessionStorageCache = Cache.createInstance({
                keyPrefix: "sessionAuthCache",
                storage: window.sessionStorage
            });

            Auth.configure({
                storage: sessionStorageCache
            });
        }
    }

    handleLogout() {
        if (this.state.signedIn) {
            this.setState({ isSigningOut: true });
            Auth.signOut()
                .then((data) => {
                    this.setState({ 
                        signedIn: false, 
                        isSigningOut: false,
                        tokenId: '',
                        refreshToken: ''
                    });
                    console.log(data);
                })
                .catch((err) => {
                    this.setState({ isSigningOut: false });
                    console.log(err);
                });
        }
    }

    componentDidMount() {
        this.setState({ isSigningIn: true });
        Auth.currentSession()
            .then((userSession) => {
                console.log("Got user currentSession!");
                console.log(userSession);

                this.setState({
                    signedIn: true, 
                    isSigningIn: false,
                    tokenId: userSession.idToken.jwtToken,
                    refreshToken: userSession.refreshToken.token
                });
            })
            .catch((err) => {
                this.setState({ isSigningIn: false });
                console.log(err)
            });
    }*/

    state = {
        devices: []
    }

    async componentDidMount(){
        
        var devices = await getMyBoards();
        var temppo = [];

        for(let device of devices) {
            if(device.typeName.split('_')[1][0] == 'M'){
                for(let thing in device.things){
                    console.log(device.things[thing].name);
                    
                    let canal=0;

                    if(device.things[thing].chanel_A)
                    {
                        canal += 1;
                    }
                    if(device.things[thing].chanel_B)
                    {
                        canal += 2;
                    }
                    if(device.things[thing].chanel_C)
                    {
                        canal += 4;
                    }

                    temppo.push({
                        name: device.things[thing].name,
                        canal: canal,
                        topic: device.topic,
                        reference: device.reference,
                    })
                }
            }
        }
        
        this.setState({
            devices: temppo,
        });
    }
    
    render() {
        return (
            <Container>
                <Container >
                    <TableDevices devices={this.state.devices} />
                </Container>
            </Container>
        );
    }
}

export default HomePage;